import { AppRoleEnum } from '@ulysses-inc/harami_api_client'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import Yup from 'src/features/validation/yup'
import { UpdateUserV2Input, updateUserV2 } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import { UserForm } from './EditUser'

const userUpdateSchema = Yup.object().shape({
  role: Yup.number().required().label('権限'),
  groupIds: Yup.array(),
  placeNodeIds: Yup.array(),
})

export const useFormikProps = () => {
  const activeUser = useSelector(({ usersState }: RootState) => {
    const activeUserId = usersState.users.activeUserId
    return activeUserId
      ? usersState.users.users.find(({ uuid }) => uuid === activeUserId)
      : undefined
  })
  const placeNodes = useSelector(
    ({ placesState }: RootState) => placesState.placeNodes.nodes,
  )

  const dispatch = useDispatch()
  const dispatchUpdateUser = (user: UpdateUserV2Input) => {
    dispatch(updateUserV2(user))
  }

  return useFormik<UserForm>({
    initialValues: {
      role: activeUser?.role?.role ?? AppRoleEnum.REPORTER,
      groupIds: activeUser?.userGroups?.map(({ id }) => `${id ?? ''}`) ?? [],
      placeNodeIds: activeUser?.placeNodes?.map(({ uuid }) => uuid) ?? [],
    },
    validationSchema: userUpdateSchema,
    onSubmit: ({ role, groupIds, placeNodeIds }) => {
      dispatchUpdateUser({
        uuid: activeUser?.uuid ?? '',
        role,
        userGroupIds: groupIds
          .map(id => parseInt(id, 10))
          .filter(id => !isNaN(id)),
        placeNodeUuids: placeNodeIds,
      })
      if (
        localStorage.getItem(localStorageKeys.loginUserUuid) ===
        activeUser?.uuid
      ) {
        localStorage.setItem(localStorageKeys.loginUserRole, role.toString())
        const updatePlaceNodes = placeNodes.filter(({ uuid }) =>
          placeNodeIds.some(placeNodeUUID => placeNodeUUID === uuid),
        )
        localStorage.setItem(
          localStorageKeys.loginUserPlaceNodes,
          JSON.stringify(updatePlaceNodes),
        )
      }
    },
  })
}
