import { useQuery } from '@tanstack/react-query'
import {
  AppRoleEnum,
  CompanyContractPlan,
  GetLoginUserContextResponse,
  LoginUserApi,
  PlaceNode,
} from '@ulysses-inc/harami_api_client'
import { commonQueryKeys } from 'src/features/tanStackQuery/commonQueries/commonQueryKeys'
import { baseClient } from 'src/state/middleware/saga/baseClient'

export class AuthError extends Error {}

type LoginUserContext = {
  companyId: number
  companyName: string
  contractPlan: CompanyContractPlan
  userLoginId: string
  userName: string
  userPlaceNodes: PlaceNode[]
  userRole: AppRoleEnum
  userUuid: string
  rumpRealmId: string
}

type Options = {
  noCache?: boolean
}

const noCacheConfig = {
  staleTime: 0,
  gcTime: 0,
} as const

export const useQueryLoginUserContext = (options?: Options) => {
  const { noCache = false } = options ?? {}

  return useQuery({
    queryKey: commonQueryKeys.loginUserContext,
    queryFn: async (): Promise<GetLoginUserContextResponse> => {
      try {
        return await baseClient.getApi(LoginUserApi).getLoginUserContext()
      } catch (e) {
        // リクエストヘッダにAPIキーがセットされていない場合は400が、キーが期限切れの場合は401が返ってくる
        if (e instanceof Response && (e.status === 400 || e.status === 401)) {
          throw new AuthError()
        }
        // 未知のエラー
        throw e
      }
    },
    select: (data): LoginUserContext => {
      return {
        contractPlan: data.contractPlan,
        companyId: data.companyId,
        companyName: data.companyName,
        userLoginId: data.userLoginId,
        userName: data.userName,
        userPlaceNodes: data.placeNodes,
        userRole: data.userRole,
        userUuid: data.userUuid,
        rumpRealmId: data.rumpRealmId,
      }
    },
    ...(noCache && noCacheConfig),
  })
}
